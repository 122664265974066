import {
    FETCH_PREPARECHECKOUT_REQUEST,
    FETCH_PREPARECHECKOUT_SUCCESS,
    FETCH_PREPARECHECKOUT_FAILURE,
    FETCH_APPLYPOINTS_REQUEST,
    FETCH_APPLYPOINTS_SUCCESS,
    FETCH_APPLYPOINTS_SUCCESS_ERROR,
    FETCH_APPLYPOINTS_FAILURE,
    FETCH_PLACEORDER_REQUEST,
    FETCH_PLACEORDER_SUCCESS,
    FETCH_PLACEORDER_FAILURE,
    FETCH_PLACEORDER_SUCCESS_ERROR,
    FETCH_CREDITCARD_REQUEST,
    FETCH_CREDITCARD_SUCCESS,
    FETCH_CREDITCARD_FAILURE,
    FETCH_CREDITCARD_SUCCESS_ERROR,
    SHOW_CREDITCARD_SECTION,
    HIDE_CREDITCARD_SECTION,
    CREDITCARD_DETAILS,
    CREDITCARD_DETAILS_CLEAR,
    CREDITCARD_NUMBER,
    CREDITCARD_CVV,
    CREDITCARD_EXPIRY,
    CREDITCARD_NAME,
    CREDITCARD_ZIPCODE,
    CREDITCARD_DETAILS_REQUIRED_ERROR,
    SAVE_CREDITCARD_CVV,
    SAVE_CREDITCARD_EXPIRY,
    SAVE_CREDITCARD_ID,
    SAVE_CREDITCARD_DETAILS_REQUIRED_ERROR,
    SAVE_CREDITCARD_DETAILS_CLEAR,
    ANALYTICS_PREPARECHECKOUT_ERROR_CODE,
    ANALYTICS_APPLYPOINTS_ERROR_CODE,
    ANALYTICS_PLACEORDER_ERROR_CODE,
    ANALYTICS_CREDITCARD_ERROR_CODE,
    VALIDATE_CARD_REQUEST,
    VALIDATE_CARD_SUCCESS,
    VALIDATE_CARD_SUCCESS_ERROR,
    VALIDATE_CARD_FAILURE,
} from './sywCheckoutPlaceOrderTypes'

const initialState = {
    spcPageLoading: false,
    applyPointsLoading: false,
    placeorderLoding:false,
    creditcardLoding:false,
    items: '',
    ffm: '',
    summary: '',
    tenders: '',
    SywPoints: '',
    CC_DC: '',
    leasing:'',
    quardsPay:'',
    apply_and_buy:'',
    spcPageError: '',
    cctype:'visa',
    error:'',
    applyPointsFailure:'',
    applyPointsError: null,
    placeOrderError: null,
    cartId: '',
    isValid: false,
    users: [],
    orderCompleteResponseCode:'',
    orderPlacedResponse:'',
    ccdetails:'',
    ccShowSavedCardFlag:true,
    ccAddNewCardFlag:false,
    ccNumber:'',
    ccCvv:'',
    ccExpiry:'',
    ccName:'',
    ccZipcode:'',
    ccDetailsRequiredError:'',
    saveCcCvv:'',
    saveCcExpiry:'',
    saveCcId:'',
	memberNumber:'',
    emailHash:'',
    sywrUserId:'',
    analyticsAPIErrorCode:'',
    cmpCode:'',
    prepareResponse:'',
    validateCardLoading: false,
    validateCardSuccess: false,
    isCardValid: false,
    validateCardResponse: "",
    validateCardError: "",
    }

const reducer = (state = initialState, action) => {
let cc_dc_temp=undefined;
let syw_points_temp=undefined;
let quardsPay_temp=undefined;
let leasing_temp=undefined;
let apply_and_buy_temp=undefined;
    if(action.payload!==undefined && action.payload.tenders!==undefined){
    const tender_temp=action.payload.tenders;


        { tender_temp.map(tender => {
            if ("CC_DC" === tender.tenderType) {
                cc_dc_temp= tender;
            }
            if ("SYW_POINTS" === tender.tenderType) {
                syw_points_temp= tender;
            }
            if ("QUADSPAY" === tender.tenderType) {
                quardsPay_temp= tender;
            }
            if ("LEASING" === tender.tenderType) {
                leasing_temp= tender;
            }
            if ("APPLYANDBUY" === tender.tenderType) {
                apply_and_buy_temp= tender;
            }
            });
        }
    }

    switch (action.type) {
        case FETCH_PREPARECHECKOUT_REQUEST:
            return {
        ...state,
    spcPageLoading: true
      }
    case FETCH_PREPARECHECKOUT_SUCCESS:
return {
    ...state,
    spcPageLoading: false,
    items: action.payload.items,
    ffm: action.payload.ffm,
    summary: action.payload.summary,
    tenders: action.payload.tenders,
    CC_DC:cc_dc_temp,
    SYW_POINTS:syw_points_temp,
    quardsPay:quardsPay_temp,
    leasing:leasing_temp,
    apply_and_buy:apply_and_buy_temp,
    spcPageError: '',
    cartId: action.payload.cartId,
    isValid: action.payload.isValid,
    userSessionId:action.payload.userSessionId,
	memberNumber:action.payload.memberNumber,
    emailHash:action.payload.emailHash,
    sywrUserId:action.payload.sywrUserId,
    //applyPointsError:action.payload.errors,
    //users: action.payload,
    analyticsAPIErrorCode:ANALYTICS_PREPARECHECKOUT_ERROR_CODE,
    cmpCode:action.payload.cmpCode,
    prepareResponse: action.payload
}
    case FETCH_PREPARECHECKOUT_FAILURE:
return {
    spcPageLoading: false,
    items: '',
    ffm: '',
    summary: '',
    tenders: '',
    CC_DC:'',
    apply_and_buy:'',
    SYW_POINTS:'',
    quardsPay:'',
    leasing:'',
    cartId: '',
    isValid: false,
    userSessionId:'',
    spcPageError: action.payload,
	memberNumber:'',
    emailHash:'',
    sywrUserId:'',
    applyPointsError: null,
    analyticsAPIErrorCode:ANALYTICS_PREPARECHECKOUT_ERROR_CODE,
    cmpCode:'',
}

        case FETCH_APPLYPOINTS_REQUEST:
return {
        ...state,
    applyPointsLoading: true,
    placeorderLoding:true,

}
    case FETCH_APPLYPOINTS_SUCCESS:
    return {
    ...state,
    applyPointsLoading: false,
    placeorderLoding:false,
    summary: action.payload.summary,
    tenders: action.payload.tenders,
    SYW_POINTS:syw_points_temp,
    error: '',
    applyPointsFailure:'',
    placeOrderError: null,
    applyPointsError: null,
}
case FETCH_APPLYPOINTS_SUCCESS_ERROR:
    return {
    ...state,
    applyPointsLoading: false,
    placeorderLoding:false,
    applyPointsFailure:'',
    applyPointsError:action.payload.errors,
    analyticsAPIErrorCode:ANALYTICS_APPLYPOINTS_ERROR_CODE,

}
    case FETCH_APPLYPOINTS_FAILURE:
return {
    ...state,
    applyPointsLoading: false,
    placeorderLoding:false,
    applyPointsFailure: action.payload
}
 case FETCH_PLACEORDER_REQUEST:
return {
        ...state,
    spcPageLoading: true
}
    case FETCH_PLACEORDER_SUCCESS:
    return {
    spcPageLoading: false,
    orderCompleteResponseCode:action.payload.responseCode,
    placeOrderError: null,
    orderPlacedResponse:action.payload,
    spcPageError: '',
    applyPointsError: null,

}
case FETCH_PLACEORDER_SUCCESS_ERROR:
    return {
    ...state,
    spcPageLoading: false,
    orderCompleteResponseCode:action.payload.responseCode,
    //placeOrderError:action.payload,
    applyPointsError:action.payload.errors,
    orderPlacedResponse:action.payload,
    spcPageError: '',
    analyticsAPIErrorCode:ANALYTICS_PLACEORDER_ERROR_CODE,
}
    case FETCH_PLACEORDER_FAILURE:
return {
    //...state,
    spcPageLoading: false,
    spcPageError: action.payload,
    analyticsAPIErrorCode:ANALYTICS_PLACEORDER_ERROR_CODE,
}


        case FETCH_CREDITCARD_REQUEST:
return {
        ...state,
    creditcardLoding: true,
    placeorderLoding:true,
     spcPageLoading: true,
}
    case FETCH_CREDITCARD_SUCCESS:
    return {
    ...state,
    creditcardLoding: false,
    placeorderLoding:false,
     spcPageLoading: false,
    CC_DC:cc_dc_temp,
    summary: action.payload.summary,
    tenders: action.payload.tenders,
    apply_and_buy:apply_and_buy_temp,
    error: '',
    cctype:'amex',
    applyPointsFailure:'',
    placeOrderError: null,
    applyPointsError: null,
}
case FETCH_CREDITCARD_SUCCESS_ERROR:
    return {
    ...state,
     spcPageLoading: false,
    creditcardLoding: false,
    placeorderLoding:false,
    applyPointsFailure:'',
     //placeOrderError:action.payload,
    applyPointsError:action.payload.errors,
    analyticsAPIErrorCode:ANALYTICS_CREDITCARD_ERROR_CODE,

}
    case FETCH_CREDITCARD_FAILURE:
return {
    ...state,
    creditcardLoding: false,
    placeorderLoding:false,
    applyPointsFailure: action.payload
}
case SHOW_CREDITCARD_SECTION:
return {
    ...state,
    ccShowSavedCardFlag:false,
    ccAddNewCardFlag:true,
}
case HIDE_CREDITCARD_SECTION:
return {
    ...state,
    ccShowSavedCardFlag:true,
    ccAddNewCardFlag:false,
}
case CREDITCARD_NUMBER:
return {
    ...state,
     ccCvv: '',
    ccNumber:action.payload,
}
case CREDITCARD_CVV:
return {
    ...state,
    ccCvv:action.payload,
}
case CREDITCARD_EXPIRY:
return {
    ...state,
    ccExpiry:action.payload,
}
case CREDITCARD_NAME:
return {
    ...state,
    ccName:action.payload,
}
case CREDITCARD_ZIPCODE:
return {
    ...state,
    ccZipcode:action.payload,
}
case CREDITCARD_DETAILS_REQUIRED_ERROR:
return {
    ...state,
    ccDetailsRequiredError:action.payload,
}
case CREDITCARD_DETAILS_CLEAR:
return {
    ...state,
    ccNumber: '',
    ccCvv: '',
    ccExpiry: '',
    ccName: '',
    ccZipcode: '',
    ccDetailsRequiredError:'',
}

case SAVE_CREDITCARD_CVV:
return {
    ...state,
    saveCcCvv:action.payload,
}
case SAVE_CREDITCARD_EXPIRY:
return {
    ...state,
    saveCcExpiry:action.payload,
}
case SAVE_CREDITCARD_ID:
return {
    ...state,
    saveCcId:action.payload,
}
case SAVE_CREDITCARD_DETAILS_CLEAR:
return {
    ...state,
    saveCcCvv:'',
    saveCcId:''
}
case VALIDATE_CARD_REQUEST:
    return {
        ...state,
        validateCardLoading: true,
        validateCardResponse: "",
        validateCardSuccess: false,
        validateCardError: "",
        isCardValid: false,
    };
case VALIDATE_CARD_SUCCESS:
return {
    ...state,
    validateCardLoading: false,
    validateCardResponse: action.payload,
    validateCardSuccess: true,
    pageApiError: null,
    isCardValid: true,
    // pageError: "",
};
case VALIDATE_CARD_SUCCESS_ERROR:
return {
    ...state,
    validateCardLoading: false,
    apiError: true,
    apiFailure: false,
    // pageApiError: action.payload.errors,
    validateCardError: action.payload,
    validateCardSuccess: false,
    isCardValid: false,
};
case VALIDATE_CARD_FAILURE:
    return {
        ...state,
        validateCardLoading: false,
        validateCardError: action.payload,
        pageError: action.payload,
        validateCardSuccess: false,
        isCardValid: false,
    };
    default: return state
  }
}

export default reducer
