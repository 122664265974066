import axios from 'axios'
import cookie from 'react-cookies';
import * as rdd from 'react-device-detect';
import * as duui from'device-uuid'
import {
    FETCH_PREPARECHECKOUT_REQUEST,
    FETCH_PREPARECHECKOUT_SUCCESS,
    FETCH_PREPARECHECKOUT_FAILURE,
    FETCH_APPLYPOINTS_REQUEST,
    FETCH_APPLYPOINTS_SUCCESS,
    FETCH_APPLYPOINTS_SUCCESS_ERROR,
    FETCH_APPLYPOINTS_FAILURE,
    FETCH_PLACEORDER_REQUEST,
    FETCH_PLACEORDER_SUCCESS,
    FETCH_PLACEORDER_FAILURE,
    FETCH_PLACEORDER_SUCCESS_ERROR,
    FETCH_CREDITCARD_REQUEST,
    FETCH_CREDITCARD_SUCCESS,
    FETCH_CREDITCARD_FAILURE,
    FETCH_CREDITCARD_SUCCESS_ERROR,
    SHOW_CREDITCARD_SECTION,
    HIDE_CREDITCARD_SECTION,
    CREDITCARD_DETAILS,
    CREDITCARD_DETAILS_CLEAR,
    CREDITCARD_NUMBER,
    CREDITCARD_CVV,
    CREDITCARD_EXPIRY,
    CREDITCARD_NAME,
    CREDITCARD_ZIPCODE,
    CREDITCARD_DETAILS_REQUIRED_ERROR,
    SAVE_CREDITCARD_CVV,
    SAVE_CREDITCARD_EXPIRY,
    SAVE_CREDITCARD_ID,
    SAVE_CREDITCARD_DETAILS_REQUIRED_ERROR,
    SAVE_CREDITCARD_DETAILS_CLEAR,
    VALIDATE_CARD_REQUEST,
    VALIDATE_CARD_SUCCESS_ERROR,
    VALIDATE_CARD_SUCCESS,
    VALIDATE_CARD_FAILURE,
} from './sywCheckoutPlaceOrderTypes'


//preparecheckout API Call Start
export const fetchPrepareCheckout = (requestBody, requestOptions, refid) => {
       return (dispatch) => {
        dispatch(fetchPrepareCheckoutRequest())
        axios.post(process.env.REACT_APP_SYW_CHECKOUT_PREPARE_URL,
            requestBody, requestOptions
        ).then(resp => {

            dispatch(fetchPrepareCheckoutSuccess(resp.data))

            if (resp.data.isValid) {
                if(resp.data.cartId){
                    cookie.save('cartId', resp.data.cartId, { maxAge:parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain:'.shopyourway.com', path:'/' });
                }
                if(refid){
                    cookie.save('refid', refid, {maxAge:parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain:'.shopyourway.com', path:'/' });
                }
            }
        })
            .catch(err => {
                cookie.remove("cartId", {domain:'.shopyourway.com', path:'/'});
                cookie.remove("refid", {domain:'.shopyourway.com', path:'/'});
                dispatch(fetchPrepareCheckoutFailure(err.message))
            });
    }
}

export const fetchPrepareCheckoutRequest = () => {
    return {
        type: FETCH_PREPARECHECKOUT_REQUEST
    }
}

export const fetchPrepareCheckoutSuccess = apiData => {
    return {
        type: FETCH_PREPARECHECKOUT_SUCCESS,
        payload: apiData
    }
}

export const fetchPrepareCheckoutFailure = error => {
    return {
        type: FETCH_PREPARECHECKOUT_FAILURE,
        payload: error
    }
}

//preparecheckout API Call END

//applypoints API Call Start

export const fetchApplyPoints = (sywPointsInDollar, requestOptions) => {
    return (dispatch) => {
        dispatch(fetchApplyPointsRequest())
        axios.post(process.env.REACT_APP_SYW_CHECKOUT_APPLY_POINTS_URL,
            { pointsInDollar: sywPointsInDollar },
            requestOptions).then(resp => {
                if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
                    dispatch(fetchApplyPointsSuccessError(resp.data))
                } else {
                    dispatch(fetchApplyPointsSuccess(resp.data))
                }
            })
            .catch(err => {
                dispatch(fetchApplyPointsFailure(err.message))
            });
    }
}


export const fetchApplyPointsRequest = () => {
    return {
        type: FETCH_APPLYPOINTS_REQUEST
    }
}

export const fetchApplyPointsSuccess = apiData => {
    return {
        type: FETCH_APPLYPOINTS_SUCCESS,
        payload: apiData
    }
}
export const fetchApplyPointsSuccessError = apiData => {
    return {
        type: FETCH_APPLYPOINTS_SUCCESS_ERROR,
        payload: apiData
    }
}
export const fetchApplyPointsFailure = error => {
    return {
        type: FETCH_APPLYPOINTS_FAILURE,
        payload: error
    }
}

//applypoints API Call End


//placeOrder API Call Start


export const fetchPlaceOrder = (requestOptions) => {
    return (dispatch) => {
        dispatch(fetchPlaceOrderRequest())
        requestOptions.headers.refid=cookie.load("refid");
        // requestOptions.headers.roToken = cookie.load("roToken");
        requestOptions.headers.userAgent =rdd.getUA;
        requestOptions.headers.osName  =rdd.osName;
        requestOptions.headers.browserName  =rdd.browserName;
        requestOptions.headers.deviceType =rdd.deviceType;
        requestOptions.headers.isBrowser =rdd.isBrowser;
        requestOptions.headers.isMobile =rdd.isMobile;
        requestOptions.headers.deviceId =duui.DeviceUUID().get();
        axios.get(process.env.REACT_APP_SYW_CHECKOUT_PLACE_ORDER_URL,
            requestOptions).then(resp => {
                   if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
                        dispatch(fetchPlaceOrderSuccessError(resp.data))
                } else if (resp.data.responseCode === "200") {
                    dispatch(fetchPlaceOrderSuccess(resp.data))
                    // cookie.remove("cartId", {domain:'.shopyourway.com', path:'/'});
                    // cookie.remove("refid", {domain:'.shopyourway.com', path:'/'});
                }
            })
            .catch(err => {
                dispatch(fetchPlaceOrderFailure(err.message))
            });
    }
}

export const fetchPlaceOrderAndUpdateCard = (requestOptions, requestBody) => {
    return async (dispatch) => {

        dispatch(fetchCreditCardRequest())
        dispatch(fetchPlaceOrderRequest())
        try {

            let resp = await axios.post(process.env.REACT_APP_SYW_PAYMENT_UPDATE_URL, requestBody, requestOptions)
            if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
                dispatch(creditCardDetailsClear());
                dispatch(fetchCreditCardSuccessError(resp.data))
            } else {
                dispatch(fetchCreditCardSuccess(resp.data));
                dispatch(hideCreditCardSection());
                dispatch(creditCardDetailsClear());
                dispatch(fetchPlaceOrderRequest())
                requestOptions.headers.refid=cookie.load("refid");
                // requestOptions.headers.roToken=cookie.load("roToken");
                requestOptions.headers.userAgent =rdd.getUA;
                requestOptions.headers.osName  =rdd.osName;
                requestOptions.headers.browserName  =rdd.browserName;
                requestOptions.headers.deviceType =rdd.deviceType;
                requestOptions.headers.isBrowser =rdd.isBrowser;
                requestOptions.headers.isMobile =rdd.isMobile;
                requestOptions.headers.deviceId =duui.DeviceUUID().get();

                try {
                    let resp = await axios.get(process.env.REACT_APP_SYW_CHECKOUT_PLACE_ORDER_URL, requestOptions);

                    if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
                        dispatch(fetchPlaceOrderSuccessError(resp.data))
                    } else if (resp.data.responseCode === "200") {
                        dispatch(fetchPlaceOrderSuccess(resp.data))
                        // cookie.remove("cartId", {domain:'.shopyourway.com', path:'/'});
                        // cookie.remove("refid", {domain:'.shopyourway.com', path:'/'});
                    }
                }
                catch (err) {
                    dispatch(creditCardDetailsClear());
                    dispatch(fetchPlaceOrderFailure(err.message))
                }
            }
        } catch (err) {
            dispatch(fetchCreditCardFailure(err.message))
        }



    }
}


export const fetchPlaceOrderRequest = () => {
    return {
        type: FETCH_PLACEORDER_REQUEST
    }
}

export const fetchPlaceOrderSuccess = apiData => {
    return {
        type: FETCH_PLACEORDER_SUCCESS,
        payload: apiData
    }
}
export const fetchPlaceOrderSuccessError = apiData => {
    return {
        type: FETCH_PLACEORDER_SUCCESS_ERROR,
        payload: apiData
    }
}
export const fetchPlaceOrderFailure = error => {
    return {
        type: FETCH_PLACEORDER_FAILURE,
        payload: error
    }
}

//placeOrder API Call End


//CreditCard API Call Start

export const fetchCreditCard = (requestOptions,requestBody) => {
    return (dispatch) => {
        dispatch(fetchCreditCardRequest())
        axios.post(process.env.REACT_APP_SYW_PAYMENT_ADD_URL,
            requestBody,
            requestOptions).then(resp => {
                if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
                    dispatch(fetchCreditCardSuccessError(resp.data))
                } else {
                    dispatch(fetchCreditCardSuccess(resp.data));
                     dispatch(hideCreditCardSection());
                    dispatch(creditCardDetailsClear());
                }
            })
            .catch(err => {
                dispatch(fetchCreditCardFailure(err.message))
            });
    }
}

export const updateCreditCard = (requestOptions,requestBody) => {
    return (dispatch) => {
        dispatch(fetchCreditCardRequest())
         axios.post(process.env.REACT_APP_SYW_PAYMENT_UPDATE_URL,
            requestBody,
            requestOptions).then(resp => {
                     if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
                    dispatch(fetchCreditCardSuccessError(resp.data))
                } else {
                    dispatch(fetchCreditCardSuccess(resp.data));
                     dispatch(hideCreditCardSection());
                     dispatch(creditCardDetailsClear());
                }
            })
            .catch(err => {
                dispatch(fetchCreditCardFailure(err.message))
            });
    }
}

export const fetchCreditCardRequest = () => {
    return {
        type: FETCH_CREDITCARD_REQUEST
    }
}

export const fetchCreditCardSuccess = apiData => {
    return {
        type: FETCH_CREDITCARD_SUCCESS,
        payload: apiData
    }
}
export const fetchCreditCardSuccessError = apiData => {
    return {
        type: FETCH_CREDITCARD_SUCCESS_ERROR,
        payload: apiData
    }
}
export const fetchCreditCardFailure = error => {
    return {
        type: FETCH_CREDITCARD_FAILURE,
        payload: error
    }
}

//CreditCard API Call End

//Credit card section show or not
export const showCreditCardSection = apiData => {
    return {
        type: SHOW_CREDITCARD_SECTION,
        payload: apiData
    }
}
export const hideCreditCardSection = error => {
    return {
        type: HIDE_CREDITCARD_SECTION,
        payload: error
    }
}
//Credit card section show or not END




//Credit card details
export const creditCardDetailsData = (target) => {
    return (dispatch) => {
        if (target.name === "number") {
            dispatch(creditCardNumber(target.value))
        } else if (target.name === "expiry") {
            dispatch(creditCardExpiry(target.value))
        } else if (target.name === "cvc") {
            dispatch(creditCardCvv(target.value))
        } else if (target.name === "name") {
            dispatch(creditCardName(target.value))
        } else if (target.name === "zipcode") {
            dispatch(creditCardZipcode(target.value))
        }
    }
}
export const creditCardNumber = apiData => {
    return {
        type: CREDITCARD_NUMBER,
        payload: apiData
    }
}
export const creditCardCvv = apiData => {
    return {
        type: CREDITCARD_CVV,
        payload: apiData
    }
}
export const creditCardExpiry = apiData => {
    return {
        type: CREDITCARD_EXPIRY,
        payload: apiData
    }
}
export const creditCardName = apiData => {
    return {
        type: CREDITCARD_NAME,
        payload: apiData
    }
}
export const creditCardZipcode = apiData => {
    return {
        type: CREDITCARD_ZIPCODE,
        payload: apiData
    }
}
export const creditCardDetailsRequiredError = apiData => {
    return {
        type: CREDITCARD_DETAILS_REQUIRED_ERROR,
        payload: "All fields is mandatory, Please fill details in all fields"
    }
}
//Credit card details
//Credit card details clear
export const creditCardDetailsClear = error => {
    return {
        type: CREDITCARD_DETAILS_CLEAR,
        payload: ''
    }
}
//Credit card details clear

//Save Credit card details

export const saveCreditCardDetailsData = (target) => {
    return (dispatch) => {
        if (target.name === "saveCvv") {
            dispatch(saveCreditCardCvv(target.value))
        } else if (target.name === "saveCcId") {
            if(target.value===''){
                dispatch(saveCreditCardCvv(''))
                dispatch(saveCreditCardExpiry(''))
                dispatch(saveCreditCardId(target.value))
            }
           else if(target.value.flag===0){
                dispatch(saveCreditCardId(target.value))
            }
            else{
                dispatch(saveCreditCardId(JSON.parse(target.value)))
            }
        }else if (target.name === "saveExpiry") {
            dispatch(saveCreditCardExpiry(target.value))
        }
    }
}
export const saveCreditCardCvv = apiData => {
    return {
        type: SAVE_CREDITCARD_CVV,
        payload: apiData
    }
}
export const saveCreditCardId = apiData => {
    return {
        type: SAVE_CREDITCARD_ID,
        payload: apiData
    }
}
export const saveCreditCardExpiry = apiData => {
    return {
        type: SAVE_CREDITCARD_EXPIRY,
        payload: apiData
    }
}
//Save Credit card details

//Save Credit card details clear
export const saveCreditCardDetailsClear = error => {
    return {
        type: SAVE_CREDITCARD_DETAILS_CLEAR,
        payload: ''
    }
}
//Save Credit card details clear

export const fetchValidateCard = (requestBody, requestHeaders) => {
    return (dispatch) => {
        dispatch(validateCardRequest());
        axios.post(process.env.REACT_APP_VALIDATE_CARD_URL , requestBody,requestHeaders
        ).then(resp => {
            if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
                dispatch(validateCardSuccessError(resp.data));
            }
            else {
                dispatch(validateCardSuccess(resp.data));
            }
        }).catch(err => {
            dispatch(validateCardFailure(err.message));
        });
    }
}

export const validateCardRequest = () => {
    return {
        type: VALIDATE_CARD_REQUEST,
    }
}

export const validateCardSuccessError = apiData => {
    return {
        type: VALIDATE_CARD_SUCCESS_ERROR,
        payload: apiData
    }
}

export const validateCardSuccess = apiData => {
    return {
        type: VALIDATE_CARD_SUCCESS,
        payload: apiData
    }
}

export const validateCardFailure = error => {
    return {
        type: VALIDATE_CARD_FAILURE,
        payload: error
    }
}
