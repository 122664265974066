import React, { Component } from "react";
import axios from "axios";
import Payment from "payment";
import { GiConfirmed } from "react-icons/gi";
import info from "../SywCheckoutImages/Info.svg";
import visa from "../SywCheckoutImages/visa.svg";
import mastercard from "../SywCheckoutImages/masterCard.svg";
import creditcardplane from "../SywCheckoutImages/creditcardplane.png";
import ccmastercard from "../SywCheckoutImages/mastercard.png";
import discover from "../SywCheckoutImages/discover.svg";
import greenTickCC from "../SywCheckoutImages/greentickcc.svg";
import errorCCcross from "../SywCheckoutImages/errorcccross.svg";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatNumber,
  miniloadingWithNoText,
  pointsOnDollar,
  validateCreditCardNumber,
  validateCVC
} from "../sywCheckoutPaymentComponent/SywCheckoutCardUtil";
import { connect } from "react-redux";
import { fetchPrepareCheckout } from "../redux";
import { bindActionCreators ,  fetchValidateCard} from "redux";
import cookie from "react-cookies";
import * as asyContain from "../redux/sywCheckoutPlaceOrder/sywCheckoutPlaceOrderActions";
import SywCheckoutCardIcon from "./SywCheckoutCardIcon";
import {CODE_200,LOADING,headers,CHANGE, CREDIT_CARD_NUM_MAX_LENGTH, CREDIT_CARD_NUM_MIN_LENGTH} from "../sywCheckoutConstantComponent/SywCheckoutConstant"
import { submitCitiredirect } from "../sywCheckoutPaymentComponent/SywCitiRedirect";
import { PaymentIcon } from "react-svg-credit-card-payment-icons";

class SywCheckoutCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      txt1: "",
      txt2: "",
      txt3: "",
      txt4: "",
      txt5: "",
      txt6: "",
      validateCCFieledStatus: false,
      fieldErrorscvc: "",
      fieldErrorsexpiry: "",
      fieldErrorsname: "",
      fieldErrorsnumber: "",
      fieldErrorszipcode: "",
      fieldErrorsbtnvalidate: "",
      validateCCFieledfilled: false,
      ccShowSavedCardFlag: true,
      ccAddNewCardFlag: false,
      ccSelectRedioValue: "",
      ccCvvHandleValue: "",
      ccExpiryHandleValue: "",
      fieldErrorscccvc: "",
      fieldErrorsccexpiry: "",
      showValidationStatus: true,
      prevCCNumber: "",
     };
    this.validateCardNumber = this.validateCardNumber.bind(this);
    this.pointDollarbtn = React.createRef();
  }

  handleInputFocus = e => {
    this.setState({ focus: e.target.name });
  };

ccSelectRedio = e => {
if(e.target.value!='')
    this.setState({ ccSelectRedioValue:JSON.parse( e.target.value).id });
    else{
    this.setState({ ccSelectRedioValue:''});    
  }
    this.setState({ ccCvvHandleValue: "" });
    this.setState({ ccExpiryHandleValue: "" });   
    this.setState({ fieldErrorscccvc: "" }); 
    this.setState({ fieldErrorsccexpiry: "" });
    this.props.saveCreditCardDetailsData(e.target);
  };
  ccSelectedRedio (target) {
    this.setState({ ccSelectRedioValue: target.value.id });
    this.setState({ ccCvvHandleValue: "" });
    this.setState({ ccExpiryHandleValue: "" });
    this.setState({ fieldErrorscccvc: "" });
    this.setState({ fieldErrorsccexpiry: "" });
    this.props.saveCreditCardDetailsData(target);
  };
ccSaveCardHnadle = ({ target }) => {
    if (target.name === "saveExpiry") {
      target.value = formatExpirationDate(target.value);
      this.setState({ ccExpiryHandleValue: target.value });
      this.props.saveCreditCardDetailsData(target); 
      if (!Payment.fns.validateCardExpiry(target.value)) {
        document
          .getElementById("fieldErrorsccexpiry")
          .classList.remove("error-text-display-none");
        document
          .getElementById("fieldErrorsccexpiry")
          .classList.add("error-text-display");
        this.setState({ fieldErrorsccexpiry: "Enter the Valid expiry date" });
      } else {
        if (
          this.state.fieldErrorscccvc === undefined ||
          this.state.fieldErrorscccvc === ""
        ) {
          document
            .getElementById("fieldErrorsccexpiry")
            .classList.remove("error-text-display");
          document
            .getElementById("fieldErrorsccexpiry")
            .classList.add("error-text-display-none");
        }
        this.setState({ fieldErrorsccexpiry: "" });
      }
    }
    if (target.name === "saveCvv") {
      target.value = formatCVC(target.value, "mastercard");
      this.setState({ ccCvvHandleValue: target.value });
      this.props.saveCreditCardDetailsData(target);
      if (!validateCVC( target.value,"mastercard")) {
          // document
          //   .getElementById("fieldErrorsccexpiry")
          //   .classList.remove("error-text-display-none");
          // document
          //   .getElementById("fieldErrorsccexpiry")
          //   .classList.add("error-text-display");
          document
            .getElementById("fieldErrorscccvc")
            .classList.remove("error-text-display-none");
          document
            .getElementById("fieldErrorscccvc")
            .classList.add("error-text-display");
          this.setState({ fieldErrorscccvc: "Enter the Valid CVC" });
        } else {
          if (
            this.state.fieldErrorsexpiry === undefined ||
            this.state.fieldErrorsexpiry === ""
          ) {
            document
              .getElementById("fieldErrorsccexpiry")
              .classList.remove("error-text-display");
            document
              .getElementById("fieldErrorsccexpiry")
              .classList.add("error-text-display-none");
          }
          document
            .getElementById("fieldErrorscccvc")
            .classList.remove("error-text-display");
          document
            .getElementById("fieldErrorscccvc")
            .classList.add("error-text-display-none");
          this.setState({ fieldErrorscccvc: "" });
        }
    }
  };
  ccShowSavedCard = e => {
    this.props.hideCreditCardSection();
    this.props.saveCreditCardDetailsClear();
  };
  ccAddNewCard = e => {
    this.setState({
      txt1: "",
      txt2: "",
      txt3: "",
      txt4: "",
      txt5: ""
    });
    this.props.showCreditCardSection();
    this.props.saveCreditCardDetailsClear();
  };

  componentDidUpdate() {
    if (
      this.state.txt1 !== "" &&
      this.state.txt2 !== "" &&
      this.state.txt3 !== "" &&
      this.state.txt4 !== "" &&
      this.state.txt5 !== ""
    ) {
      if (this.state.validateCCFieledfilled !== true) {
        this.setState({ validateCCFieledfilled: true });
      }
    } else {
      if (this.state.validateCCFieledfilled !== false) {
        this.setState({ validateCCFieledfilled: false });
      }
    }
    
  }
   validateCardNumber = async ({ target }) => {
    if(target.name === "number" && this.state.txt1){
      this.setState({prevCCNumber: this.state.txt1})
      const headers = {
        "Content-Type": "application/json",
        // platform: "PYW",
      };
      headers.merchantClientId= cookie.load("merchant");
      headers.refid=cookie.load("refid")
      const requestHeaders = {
        headers,
      };
      const requestBody = {
        cardNumber: this.state.txt1
      };
      this.props.fetchValidateCard(requestBody, requestHeaders);
    }
   
  };
  handleInputChange = ({ target }) => {
    if (target.name === "number") {
      if(this.state.showValidationStatus){
        this.setState({showValidationStatus: false})
      }
      target.value = formatNumber(target.value);
      this.setState({ txt3: "", txt1: target.value });
      this.props.creditCardDetailsData(target);
      if (validateCreditCardNumber(target.value)) {
        document.getElementById("cardnumbererror").classList.add("error-message-display");
        document.getElementById("cardnumbererror").classList.remove("error-message-display-none");
        this.setState({ fieldErrorsnumber: "Enter The Valid Card Number" });
      } else {
        this.setState({ fieldErrorsnumber: "" });
        document.getElementById("cardnumbererror").classList.add("error-message-display-none");
          document.getElementById("cardnumbererror").classList.remove("error-message-display");
      }
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
      this.setState({ txt2: target.value });
      this.props.creditCardDetailsData(target);
      if (!Payment.fns.validateCardExpiry(target.value)) {
        document
          .getElementById("expiryerror")
          .classList.remove("error-text-display-none");
        document
          .getElementById("expiryerror")
          .classList.add("error-text-display");
        this.setState({ fieldErrorsexpiry: "Enter the Valid expiry date" });
      } else {
        if (
          this.state.fieldErrorscvc === undefined ||
          this.state.fieldErrorscvc === ""
        ) {
          document
            .getElementById("expiryerror")
            .classList.remove("error-text-display");
          document
            .getElementById("expiryerror")
            .classList.add("error-text-display-none");
        }
        this.setState({ fieldErrorsexpiry: "" });
      }
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value, this.state.txt1);
      this.setState({ txt3: target.value });
      this.props.creditCardDetailsData(target);
      if (this.state.txt1 === "") {
        document
          .getElementById("expiryerror")
          .classList.remove("error-text-display-none");
        document
          .getElementById("expiryerror")
          .classList.add("error-text-display");
        document
          .getElementById("cvvrerror")
          .classList.remove("error-text-display-none");
        document
          .getElementById("cvvrerror")
          .classList.add("error-text-display");
        this.setState({ fieldErrorscvc: "First enter the card number" });
      } else {
        if (
          !validateCVC(
            target.value,
            this.pageData?.validateCardResponse?.brand
          )
        ) {
          document
            .getElementById("expiryerror")
            .classList.remove("error-text-display-none");
          document
            .getElementById("expiryerror")
            .classList.add("error-text-display");
          document
            .getElementById("cvvrerror")
            .classList.remove("error-text-display-none");
          document
            .getElementById("cvvrerror")
            .classList.add("error-text-display");
          this.setState({ fieldErrorscvc: "Enter the Valid CVC" });
        } else {
          if (
            this.state.fieldErrorsexpiry === undefined ||
            this.state.fieldErrorsexpiry === ""
          ) {
            document
              .getElementById("expiryerror")
              .classList.remove("error-text-display");
            document
              .getElementById("expiryerror")
              .classList.add("error-text-display-none");
          }
          document
            .getElementById("cvvrerror")
            .classList.remove("error-text-display");
          document
            .getElementById("cvvrerror")
            .classList.add("error-text-display-none");
          this.setState({ fieldErrorscvc: "" });
        }
      }
    } else if (target.name === "name") {
      var regName =new RegExp('^[a-zA-Z ]*$');
        if(regName.test(target.value)){
          this.props.creditCardDetailsData(target);
           this.setState({ txt4: target.value});
        }

    } else if (target.name === "zipcode") {
      this.props.creditCardDetailsData(target);
      this.setState({ txt5: target.value});
    } else if (target.name === "Address") {
      this.setState({ txt6: target.value});
    }
  };


  componentDidUpdate(prevProps) {
    if (
      prevProps.pageData !== this.props.pageData ||
      prevProps.cardNumber !== this.props.cardNumber
    ) {
      const { pageData } = this.props;
  
      if (
        !pageData?.validateCardLoading &&
        !pageData?.validateCardSuccess &&
        pageData?.validateCardError
      ) {
        if(this.state.txt1 === this.state.prevCCNumber){
          this.setState({showValidationStatus : true})
        }
        document
          ?.getElementById("cardnumbererror")
          ?.classList?.add("error-message-display");
        document
          ?.getElementById("cardnumbererror")
          ?.classList?.remove("error-message-display-none");
        this.setState({ fieldErrorsnumber: "Enter The Valid Card Number" });
      } else if(pageData?.validateCardSuccess && pageData?.isCardValid ) {
        if(this.state.txt1 === this.state.prevCCNumber){
          this.setState({showValidationStatus : true})
        }
        document
          ?.getElementById("cardnumbererror")
          ?.classList?.remove("error-message-display");
        document
          ?.getElementById("cardnumbererror")
          ?.classList?.add("error-message-display-none");
        this.setState({ fieldErrorsnumber: "" });
      }
    }
  }
  




  render() {
    const { error, fieldErrors, cctestdata } = this.state;

    if (this.props.pageData.applyPointsFailure) {
      return <div>Error: {this.props.pageData.applyPointsFailure}</div>;
    } else if (this.props.pageData.spcPageLoading) {
      return (
        <section class="container loading-card mt-32">
        <div class="loader" />
        <h5 class="mt-16">Loading...</h5>
      </section>
      );
    } else {
      return (
        <div class="description">
{(() => {
            if (
              this.props.pageData.CC_DC &&
              "CC_DC" === this.props.pageData.CC_DC.tenderType
            ) {
              return (
              <div>
          <div class="item mb-8">
            <div class="item-image" >
              <h5 class="mb-8">
                <b className="mb-4 baby-blue">Credit Card: </b>
              </h5>
            </div>
            <div class="item-points">
              <h5 class="ml-8 baby-blue">
                <img src={visa} class="icon-card " alt="" />{" "} <img src={discover} class="icon-card " alt="" /> {" "}<img src={mastercard}  class="icon-card" alt="" />{" "}

              </h5>
            </div>
          </div>
           {(() => {if(this.props.pageData.ccDetailsRequiredError != '') {
              return (
          <p class="mb-2 mt-0 red">
              <small>{this.props.pageData.ccDetailsRequiredError}</small>
          </p>
          );
            }
          })()}
          
                <div>
                {(() => {
                  if (this.props.pageData.ccShowSavedCardFlag === true
                     && ( this.props.pageData.CC_DC.cardNumber != undefined &&
                        this.props.pageData.CC_DC.cardNumber.length > 0)) {
                          return (
                            <div class="">
                              <div class="cc-card mt-16">
                              {(() => {
                  if (( this.props.pageData.CC_DC.cardNumber != undefined &&
                        this.props.pageData.CC_DC.cardNumber.length > 0)
                        && ( this.props.pageData.CC_DC.savedCards !=
                                  undefined &&
                                this.props.pageData.CC_DC.savedCards.length > 0)) {
                          return (
                              <input
                                type="radio"
                               onChange={
                                  this.ccSelectRedio
                                }
                                value={''}
                                defaultChecked
                                name="saveCcId"
                                id={`checkCustomCardRedio`}
                              />);

                             }} )()}
                              <PaymentIcon
                                  type={cookie.load("cardtype")}
                                  format="flatRounded"
                                />
                                  <p class="mt-0 capitalize"> {cookie.load("cardtype")}
                                    {" Card ending with "}
                                    {this.props.pageData.CC_DC.cardNumber.substring(
                                      this.props.pageData.CC_DC.cardNumber
                                        .length - 4,
                                      this.props.pageData.CC_DC.cardNumber
                                        .length
                                    )}{" "}
                                    <a class="ml-16" onClick={this.ccAddNewCard}> <b>{CHANGE}</b></a>
                                  </p>
                                </div>
                              </div>);
                    }

                    }
                                        )()}
                                        {(() => {

                    if (this.props.pageData.ccShowSavedCardFlag === true
                    && ( this.props.pageData.CC_DC.savedCards !=
                                  undefined &&
                                this.props.pageData.CC_DC.savedCards.length > 0)) {
                     return this.props.pageData.CC_DC.savedCards.map(
                                  ccdata => {
                        if((this.props.pageData.CC_DC.cardNumber === undefined)
                        && this.props.pageData.saveCcId === ''&&
                        (parseFloat(this.props.pageData.summary.pointsDollarValue) !== parseFloat(this.props.pageData.summary.priceSubTotal))){
                                var cardselect={name:"saveCcId",value:{id:ccdata.paymentCardId,
                                    cvvreq:ccdata.cvvRequired,expiryreq:ccdata.expiryRequired,flag:0,selected:ccdata.selected}}
                            this.ccSelectedRedio(cardselect)
                          }

                                    return (
                            <div class="">
                              <div class="cc-card mt-16">
                              {(() => {
                  if (( this.props.pageData.CC_DC.cardNumber != undefined &&
                        this.props.pageData.CC_DC.cardNumber.length > 0)
                        && ( this.props.pageData.CC_DC.savedCards !=
                                  undefined &&
                                this.props.pageData.CC_DC.savedCards.length > 0)) {
                          return (
                              <input
                                type="radio"
                                onChange={
                                  this.ccSelectRedio
                                }
                                value={'{"id":"'+ccdata.paymentCardId+'","cvvreq":"'+ccdata.cvvRequired+'","selected":"'+ccdata.selected+'","expiryreq":"'+ccdata.expiryRequired+'","flag":"1"}'}
                                name="saveCcId"
                                id={`inline-${ccdata.paymentCardId}`}
                              />); }} )()}
                                <img src={ccmastercard} alt="" />

                                  <p class="mt-0 capitalize"> {"Shop Your Way Mastercard®"}
                                    {" ending with "}
                                     {ccdata.panLastFour}
                                      {(() => { if(this.props.pageData.CC_DC.cardNumber === undefined)
                                          {
                                    return (
                                    <a class="ml-16" onClick={this.ccAddNewCard}><b>{CHANGE}</b></a>); }} )()}
                                  </p>
                                </div>
                                <p class="ml-80">
                                  {(() => {
                                          if (
                                            ccdata.paymentCardId ===
                                              this.state.ccSelectRedioValue &&
                                            ccdata.selected != "undefined" &&
                                            ccdata.selected !== "true" &&
                                                    ccdata.expiryRequired !=
                                                      "undefined" &&
                                                    ccdata.expiryRequired === "Y"
                                          ) {
                                                    return (
                                                       <input
                                                        class="input-text30 mb-2 mr-1 mt-2"
                                                        placeholder="MM/YY"
                                                        type="tel"
                                                        name="saveExpiry"
                                                        required
                                                        onChange={
                                                          this.ccSaveCardHnadle
                                                        }
                                                        value={this.state.ccExpiryHandleValue}
                                                      />  
                                                    );
                                                  }

                                          }
                                        )()}
                                         {(() => {
                                          if (
                                            ccdata.paymentCardId ===
                                              this.state.ccSelectRedioValue &&
                                            ccdata.selected !== "undefined" &&
                                           ccdata.selected !== "true" &&
                                                    ccdata.cvvRequired !=
                                                      "undefined" &&
                                                    ccdata.cvvRequired === "Y"
                                          ) {
                                                    return (
                                                      <input
                                                        class="input-text30 mb-2 ml-1 mt-2"
                                                        type="password"
                                                        name="saveCvv"
                                                        placeholder="cvv"
                                                        pattern="\d{3,4}"
                                                       required
                                                        onChange={
                                                          this.ccSaveCardHnadle
                                                        }
                                                        value={this.state.ccCvvHandleValue}
                                                      /> 
                                                    );
                                                  }

                                          }
                                        )()}

 <p class="">
                              <label
                                class="mb-2 mt-0 error-text-display-none"
                                id="fieldErrorsccexpiry"
                              >
                                <small>{this.state.fieldErrorsccexpiry}</small>
                                
                              </label>
                              <label
                                class="mb-2 mt-0 error-text-display-none"
                                id="fieldErrorscccvc"
                              >
                                <small>{this.state.fieldErrorscccvc}</small>
                              </label>
                            </p>

                                        </p>
                              </div>

                       );
                      }
                    );
                    }
                  else if ((this.props.pageData.ccAddNewCardFlag === true || !(
                                this.props.pageData.CC_DC.cardNumber !=
                                  undefined &&
                                this.props.pageData.CC_DC.cardNumber.length > 0
                              ))) {
                      return (
                        <div>

                            <p>
                              <input
                                required
                                class="input-text mb-2 mt-2"
                                placeholder="Cardholder Name"
                                type="text"
                                name="name"
                                onpaste="return false;"
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                                maxLength={30}
                                value={this.state.txt4}
                                onKeyPress={e => {
                                  if (!/[a-zA-Z ]/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                isInvalid={!!this.state.fieldErrorsname}
                                disabled={
                                  parseFloat(
                                    this.props.pageData.summary.paymentDue
                                  ) > 0 ? (
                                    false
                                  ) : (
                                    true
                                  )
                                }
                                autocomplete="off"
                              />
                            </p>
                            <p class="mb-2 mt-0" id="snameerror">
                              <small>{this.state.fieldErrorsname}</small>
                            </p>
                            <p class="mb-2 mt-0 relative">
                              <input
                                id="cardnumber"
                                required
                                class="input-text "
                                placeholder="Card Number"
                                type="tel"
                                name="number"
                                pattern="[\d| ]{16,22}"
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                                value={this.state.txt1}
                                maxLength={CREDIT_CARD_NUM_MAX_LENGTH}
                                minLength={CREDIT_CARD_NUM_MIN_LENGTH}
                                isInvalid={!!this.state.fieldErrorsnumber}
                                disabled={
                                  parseFloat(
                                    this.props.pageData.summary.paymentDue
                                  ) > 0 ? (
                                    false
                                  ) : (
                                    true
                                  )
                                }
                                autocomplete="off"
                                onBlur={(e) => this.validateCardNumber(e)}
                              />
                              <span className="card-miniloader">
                                    {this.props.pageData?.validateCardLoading ? (
                                      miniloadingWithNoText()
                                    ) : this.props.pageData?.validateCardSuccess &&  this.state.showValidationStatus && this.state.txt1 ? (
                                      <img
                                        className="greentick-cc"
                                        src={greenTickCC}
                                      />
                                    ) : this.props.pageData?.validateCardError && this.state.showValidationStatus ? (
                                      <img className="errorCCcross" src={errorCCcross} />
                                    ) : (
                                      "" 
                                    )}
                                  </span>
                              <p class="mb-2 mt-0" id="cardnumbererror">
                                <small>{this.state.fieldErrorsnumber}</small>
                              </p>
                            </p>
                            <p>
                              <input
                                required
                                class="input-text30 mb-2 mr-1 mt-0"
                                placeholder="MM/YY"
                                type="tel"
                                name="expiry"
                                value={this.state.txt2}
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                                isInvalid={!!this.state.fieldErrorsexpiry}
                                disabled={
                                  parseFloat(
                                    this.props.pageData.summary.paymentDue
                                  ) > 0 ? (
                                    false
                                  ) : (
                                    true
                                  )
                                }
                                autocomplete="off"
                              />

                              <input
                                required
                                className="input-text30 mb-2 ml-1"
                                placeholder="CVV"
                                type="password"
                                name="cvc"
                                pattern="\d{3,4}"
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                                value={this.state.txt3}
                                onpaste="return false;"
                                ondrop="return false;"
                                autocomplete="off"
                                isInvalid={!!this.state.fieldErrorscvc}
                                disabled={
                                  parseFloat(
                                    this.props.pageData.summary.paymentDue
                                  ) > 0 ? (
                                    false
                                  ) : (
                                    true
                                  )
                                }
                              />
                            </p>
                            <p>
                              <label
                                class="mb-2 mt-0 error-text-display-none"
                                id="expiryerror"
                              >
                                <small>{this.state.fieldErrorsexpiry}</small>
                              </label>
                              <label
                                class="mb-2 mt-0 error-text-display-none"
                                id="cvvrerror"
                              >
                                <small>{this.state.fieldErrorscvc}</small>
                              </label>
                            </p>

                            <p>
                              <input
                                required
                                class="input-text mb-2 mt-0"
                                placeholder="Billing Zip Code"
                                type="tel"
                                name="zipcode"
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                                maxLength={5}
                                value={this.state.txt5}
                                onKeyPress={e => {
                                  if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                isInvalid={!!this.state.fieldErrorszipcode}
                                disabled={
                                  parseFloat(
                                    this.props.pageData.summary.paymentDue
                                  ) > 0 ? (
                                    false
                                  ) : (
                                    true
                                  )
                                }
                                autocomplete="off"
                              />
                            </p>
                            <p class="mb-2 mt-0" id="zipcodeerror">
                              <small>{this.state.fieldErrorszipcode}</small>
                            </p>

                        </div>
                      );
                    }
                  })()}

                </div>
                </div>
              );
            }
          })()}
        </div>
      );
    }
  }
}
export default connect(
  state => ({
    pageData: state.pageData
  }),
  dispatch =>
    bindActionCreators(
      {
        ...asyContain
      },
      dispatch
    )
)(SywCheckoutCard);
